<template>
  <div class="showUser">
    <h4 @click="getDeptList">请选择转移人员</h4>
<!--    <van-search v-model="searchName" :placeholder="`请输入`"/>-->
    <van-loading size="24px" v-if="list.length == 0">加载中...</van-loading>
<!--    <div class="submit">-->
<!--      <van-button type="info" plain size="small" @click="onSelectAll"-->
<!--      >全选-->
<!--      </van-button-->
<!--      >-->
<!--      <van-button-->
<!--          type="warning"-->
<!--          plain-->
<!--          size="small"-->
<!--          color="#999"-->
<!--          @click="onClearAll"-->
<!--      >取消全选-->
<!--      </van-button-->
<!--      >-->
<!--    </div>-->
    <van-tree-select
        :items="list | newUserList(searchName)"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
    />
    <div class="submit">
      <van-button type="default" @click="onCancel">取消</van-button>
      <van-button type="info" @click="onConfirm">确定</van-button>
    </div>
  </div>
</template>

<script>
import {getGroupItemsInfo} from "@/api/mainDepts.js";

export default {
  name: "ChoseDept",
  components: {},
  props: {},
  data() {
    return {
      activeIndex: 0, // 左侧《下标
      activeId: [], //右侧》列表选中项 ids数组
      searchName: "", // 搜索过滤
      list: [], // ----------------待选列表, 部门+人员子级 children嵌套
      flowList: [], // 正在处理人员,用于禁选

      userListAll: [], // 所有子级项数组,用来筛选
      mainDept: null, // 当前用户部门信息
    };
  },
  computed: {
    // 用来返回到父页面
    activeList() {
      let selectedData = [];
      if (Array.isArray(this.activeId)) {
        selectedData = this.activeId.map((id) => {
          // 通过 id 查找对应的数据
          return this.userListAll.find((data) => data.id == id);
        });
      }
      return selectedData;
    },
    // 过滤后的右侧人员列表
    filterUserList() {
      return this.filterNewUserList(this.list, this.searchName);
    },
  },
  filters: {
    // 过滤选择人员
    newUserList(list, searchName) {
      let arr = [];
      if (searchName != "") {
        list.forEach((item1, index1) => {
          arr.push({
            text: item1.text,
            children: [],
          });
          item1.children.forEach((item2) => {
            if (item2.text.toLowerCase().includes(searchName.toLowerCase())) {
              arr[index1].children.push({
                id: item2.id,
                disabled: item2.disabled,
                text: item2.text,
              });
            }
          });
        });
        return arr;
      } else {
        return list;
      }
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDeptList(); // 获取部门列表
    },
    // 全选
    onSelectAll() {
      const currentChildren =
          this.filterUserList[this.activeIndex]?.children || [];
      const selectedIdsSet = new Set(this.activeId);

      currentChildren.forEach((item) => {
        if (!item.disabled) {
          selectedIdsSet.add(item.id);
        }
      });
      this.activeId = Array.from(selectedIdsSet);
    },
    // 清空当前页全选
    onClearAll() {
      const currentChildren =
          this.filterUserList[this.activeIndex]?.children || [];
      const selectedIdsSet = new Set(this.activeId);
      currentChildren.forEach((item) => {
        selectedIdsSet.delete(item.id);
      });
      this.activeId = Array.from(selectedIdsSet);
    },
    // 取消
    onCancel() {
      this.$emit("onCancel");
    },
    // 确定
    onConfirm() {
      // this.$emit("input", this.activeList);
      this.$emit("onConfirm", this.activeList);
      this.activeId = []
    },
    // 获取部门列表
    getDeptList() {
      getGroupItemsInfo({}).then((res) => {
        let allData = {
          id: "-1",
          text: "全部",
          children: [],
        };
        let data = res.prCyItems;
        // 将label赋值给text
        data.forEach((item) => {
          if (item.children) {
            item.children.forEach((child) => {
              allData.children.push(child);
              this.userListAll.push(child);
            });
          }
        });
        console.log(allData)
        data.unshift(allData);
        this.list = data;
      });
    },
    // 搭配过滤使用
    filterNewUserList(list, searchName) {
      let arr = [];
      if (searchName !== "") {
        list.forEach((item1, index1) => {
          arr.push({
            text: item1.text,
            children: [],
          });
          item1.children.forEach((item2) => {
            if (item2.text.toLowerCase().includes(searchName.toLowerCase())) {
              arr[index1].children.push({
                id: item2.id,
                disabled: item2.disabled,
                text: item2.text,
              });
            }
          });
        });
        return arr;
      } else {
        return list;
      }
    },
  },
};
</script>

<style scoped>
.showUser {
  height: 100vh;
  box-sizing: border-box;
  padding: 10px 0;
}

.showUser h4 {
  margin-bottom: 30px;
  padding-left: 30px;
  font-size: 30px;
}

.showUser .submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.showUser .submit .van-button {
  width: 150px;
}

</style>
<style>
.showUser .van-tree-select {
  margin-top: 20px;
  height: 70% !important;
}

.showUser .van-sidebar-item--select::before {
  background-color: #418af1;
}

.showUser .van-tree-select__item--active {
  color: #418af1;
}
</style>
