import request from '@/utils/request'
import {post} from "./index";

export function getMyCustomer(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/getMyCustomer.json",
        method: 'post',
        data: formFile,
    })
}

export function getTitleName(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/getTitleName.json",
        method: 'post',
        data: formFile,
    })
}

export function savePlanvisitByMyCustomer(formFile) {
    return request({
        url: "/api/ajax/planVisit/savePlanvisitByMyCustomer.json",
        method: 'post',
        data: formFile,
    })
}

export function saveReplayByMyCustomer(formFile) {
    return request({
        url: "/api/ajax/replay/saveReplayByMyCustomer.json",
        method: 'post',
        data: formFile,
    })
}

export function customerManage(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/customerManage.json",
        method: 'post',
        data: formFile,
    })
}

export function saveBulkCrmPlanvisit(formFile) {
    return request({
        url: "/api/ajax/planVisit/saveBulkCrmPlanvisit.json",
        method: 'post',
        data: formFile,
    })
}

export function checkGetCustomer(formFile) {
    return request({
        url: "/api/ajax/bulkCrmsCusList/checkGetCustomer.json",
        method: 'post',
        data: formFile,
    })
}




