<template>
  <div class="showUser">
    <h4 @click="getDeptList">{{ title }}</h4>
    <van-search v-model="searchName" :placeholder="plhoder"/>
    <van-loading size="24px" v-if="list.length == 0 && title == '请选择走访计划'">加载中...</van-loading>
<!--    <div class="submit">-->
<!--      <van-button type="info" plain size="small" @click="onSelect"-->
<!--                  :style="{color: btnClass ? '#1989fa' : '#BAB8B7', borderColor: btnClass ? '#1989fa' : '#BAB8B7'}"-->
<!--      >选择-->
<!--      </van-button-->
<!--      >-->
<!--      <van-button-->
<!--          type="warning"-->
<!--          plain-->
<!--          size="small"-->
<!--          color="#999"-->
<!--          @click="onAdd"-->
<!--          :style="{color: !btnClass ? '#1989fa' : '#BAB8B7', borderColor: !btnClass ? '#1989fa' : '#BAB8B7'}"-->
<!--      >新增-->
<!--      </van-button-->
<!--      >-->
<!--    </div>-->
    <van-cell-group v-if="title == '请手填走访计划'">
      <van-row>
        <van-col span="11" offset="1">
          <van-field placeholder="请选择计划出发时间" v-model="cardInfo.startTime"
                     @click="show = true"></van-field>
        </van-col>
        <van-col span="11" offset="1">
          <van-field placeholder="请选择计划返回时间" @click="show1 = true"
                     v-model="cardInfo.endTime"></van-field>
        </van-col>
      </van-row>

      <van-calendar v-model="show" @confirm="confirmDate" :show-confirm="false"/>

      <van-calendar v-model="show1" @confirm="confirmDate1" :show-confirm="false"/>
    </van-cell-group>

    <van-tree-select v-if="title == '请选择走访计划'"
                     :items="list | newUserList(searchName)"
                     :active-id.sync="activeId"
                     :main-active-index.sync="activeIndex"
    />
    <div class="submit">
      <van-button type="default" @click="onCancel">取消</van-button>
      <van-button type="info" @click="onConfirm">确定</van-button>
    </div>
  </div>
</template>

<script>
import {getTitleName} from "@/api/myCustomer.js";

export default {
  name: "ChoseZfjh",
  components: {},
  props: {},
  data() {
    return {
      activeIndex: 0, // 左侧《下标
      activeId: null, //右侧》列表选中项 ids数组
      searchName: "", // 搜索过滤
      list: [], // ----------------待选列表, 部门+人员子级 children嵌套
      flowList: [], // 正在处理人员,用于禁选
      userListAll: [], // 所有子级项数组,用来筛选
      mainDept: null, // 当前用户部门信息
      title: '请选择走访计划',
      plhoder: '输入查询',
      btnClass: true,
      show: false,
      show1: false,
      cardInfo: {
        startTime: '',
        endTime: '',
      },
    };
  },
  computed: {
    // 用来返回到父页面
    activeList() {
      let selectedData = {};
      if (this.activeId == null) {

      } else {
        selectedData = this.userListAll.find((data) => data.text == this.activeId)
      }
      if (this.plhoder == '手填走访计划' && this.searchName != '') {
        selectedData.text = this.searchName
        selectedData.value = Math.random().toString(36).slice(2)
      }
      return selectedData;
      this.activeId = selectedData;
    },
    // 过滤后的右侧人员列表
    filterUserList() {
      return this.filterNewUserList(this.list, this.searchName);
    },
  },
  filters: {
    // 过滤选择人员
    newUserList(list, searchName) {
      let arr = [];
      if (searchName != "") {
        list.forEach((item1, index1) => {
          arr.push({
            text: item1.text,
            children: [],
          });
          item1.children.forEach((item2) => {
            if (item2.text.toLowerCase().includes(searchName.toLowerCase())) {
              arr[index1].children.push({
                id: item2.id,
                disabled: item2.disabled,
                text: item2.text,
              });
            }
          });
        });
        return arr;
      } else {
        return list;
      }
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.userListAll = []
      this.list = []
      this.getDeptList(); // 获取部门列表
    },
    // 选择
    onSelect() {
      this.init();
      this.title = '请选择走访计划'
      this.plhoder = '查询走访计划'
      this.searchName = ''
      this.btnClass = true
    },
    // 手填
    onAdd() {
      this.list = []
      this.title = '请手填走访计划'
      this.plhoder = '手填走访计划'
      this.activeId = null
      this.searchName = ''
      this.btnClass = false
    },
    // 取消
    onCancel() {
      this.$emit("onCancel");
    },
    // 确定
    onConfirm() {
      if (this.plhoder == '手填走访计划' && this.searchName != '') {
        if(this.cardInfo.startTime == '' || this.cardInfo.endTime == ''){
          this.$toast({
            message: '请补全计划时间！',
            position: 'top'
          });
        }
        else{
          this.activeList.startTime = this.cardInfo.startTime
          this.activeList.endTime = this.cardInfo.endTime
          this.$emit("onConfirm", this.activeList);
        }
      }else{
        this.$emit("onConfirm", this.activeList);
      }
    },
    // 获取部门列表
    getDeptList() {
      getTitleName({userNo: this.$store.getters.userId}).then((res) => {
        let allData = {
          id: "-1",
          text: "全部",
          children: [],
        };
        let data = res.prCyItems;
        // 将label赋值给text
        data.forEach((item) => {
          if (item.children) {
            item.children.forEach((child) => {
              allData.children.push(child);
              this.userListAll.push(child);
            });
          }
        });
        console.log(allData)
        data.unshift(allData);
        if (data.length == 0) {
          this.$toast({
            message: '您当前没有符合条件的走访计划！',
            position: 'top'
          });
        }
        this.list = data;
      });
    },
    // 搭配过滤使用
    filterNewUserList(list, searchName) {
      let arr = [];
      if (searchName !== "") {
        list.forEach((item1, index1) => {
          arr.push({
            text: item1.text,
            children: [],
          });
          item1.children.forEach((item2) => {
            if (item2.text.toLowerCase().includes(searchName.toLowerCase())) {
              arr[index1].children.push({
                id: item2.id,
                disabled: item2.disabled,
                text: item2.text,
              });
            }
          });
        });
        return arr;
      } else {
        return list;
      }
    },
    confirmDate(date) {
      this.show = false;
      this.cardInfo.startTime = this.formatDate(date);
    },
    confirmDate1(date) {
      this.show1 = false;
      this.cardInfo.endTime = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  },
};
</script>

<style scoped>
.showUser {
  height: 100vh;
  box-sizing: border-box;
  padding: 10px 0;
}

.showUser h4 {
  margin-bottom: 30px;
  padding-left: 30px;
  font-size: 30px;
}

.showUser .submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.showUser .submit .van-button {
  width: 150px;
}

</style>
<style>
.showUser .van-tree-select {
  margin-top: 20px;
  height: 62% !important;
}

.showUser .van-sidebar-item--select::before {
  background-color: #418af1;
}

.showUser .van-tree-select__item--active {
  color: #418af1;
}
</style>
