import request from '@/utils/request'

export function getPrCyItemsInfo(formFile) {
    return request({
        url: "/api/ajax/common/getPrCyItemsInfo.json",
        method: 'post',
        data: formFile,
    })
}

export function getGroupItemsInfo(formFile) {
    return request({
        url: "/api/ajax/common/getGroupItemsInfo.json",
        method: 'post',
        data: formFile,
    })
}