<template>
  <div class="my-customer">

    <div class="fixed-button">
      <van-search
          v-model="searchCusName"
          autocomplete="off"
          class="search"
          :formatter="formatter"
          :placeholder="placeholder"
          @search="onSearch"
      />
      <!--按钮-->

      <div class="container-button">
        <div class="slide" style="width: 100px; margin-left: 10px">
          <div :class="key?'container-button-select1':'container-button-select'">
            <el-select v-model="zfjhType" placeholder="添加计划">
              <el-option
                  v-for="item in zfjhTypes"
                  :key="item.type"
                  :label="item.type"
                  :value="item.type"
                  @click.native="zfjhClick(item)">
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-for="(item,index) in data" class="slide">
             <span :class="activeType==item.type?'active':'classification'" @click="oncheck(item)">
                     {{ item.type }}
                   <van-icon size="12px"
                             :name="activeType!=item.type ? require('@/assets/unuse.png') : require('@/assets/use.png') "/>
                  </span>
        </div>
      </div>

    </div>


    <!--tab页-->
    <div class="fixed-tab">
      <div :class="item.check ? 'customerListCheck' : 'customerList'" v-for="(item,index) in myCus"
           :style="{ boxShadow: item.text=='已到期'?'1px 1px 5px red':'1px 1px 5px green'}"
           @click="boolRotate(index)"
           v-if="myCus.length>=1">
        <van-icon class="select-button" size="20px"
                  :name="item.check ? require('@/assets/已选择.png') : require('@/assets/未选择.png') "/>
        <van-row>
          <van-col span="23" offset="1" class="contain-cusName">
            {{ item.value }}
          </van-col>
        </van-row>
        <van-divider contentPosition="center" class="driver"></van-divider>
        <van-row>
          <van-col span="23" offset="1" class="contain-time">
            最后一次联系时间：<span :style="{color: item.text=='已到期'?'red':'green'}">{{ item.lastDate }}</span>
          </van-col>
        </van-row>

        <van-divider contentPosition="center" class="driver"></van-divider>

        <van-row>
          <van-col span="15" offset="1" class="contain-busgrp">
            负责人：{{ item.createdPeople }}
          </van-col>
          <van-col span="5" offset="3" class="contain-busgrp" :style="{color: item.text=='已到期'?'red':'green'}">
            <!--              <van-image width="40px" height="40px" round-->
            <!--                         :src="require('@/assets/busgrp/'+item.createdPeople+ '.png')"/>-->
            {{ item.text }}
          </van-col>
        </van-row>
        <van-row>
          <van-col span="5" offset="1" class="contain-detail" @click="toDetail(item)">
            查看详情
          </van-col>
        </van-row>
      </div>
      <div v-if="myCus.length==0">
        <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
      </div>
    </div>


    <!--选择走访计划-->
    <div>
      <Modal :visible="showModal" @close="showModal = false, activeType = '', key=false" ref="taskModal" id="taskModal">


        <van-divider class="label" contentPosition="center">选择计划</van-divider>

        <div class="zfjh">
          <van-cell-group>
            <van-field
                :value="selZfjh.text"
                input-align="center"
                placeholder="点击选择计划"
                :border="false"
                @click="showChose = true"
            />
          </van-cell-group>
          <van-popup
              v-model="showChose"
              closeable
              close-icon="close"
              position="right"
              :style="{ height: '100%', width: '100%' }"
          >
            <ChoseZfjh
                v-model="activeList"
                @onCancel="showChose = false"
                @onConfirm="onConfirmChose"
            ></ChoseZfjh>
          </van-popup>

        </div>

        <van-divider class="label" contentPosition="center">已选择客户</van-divider>
        <van-tag class="cusList" style="" v-for="(item, index) in cusList" closeable type="primary"
                 @close="close(item)">
          {{ item.value }}
        </van-tag>

        <van-divider class="label" contentPosition="center">添加其他客户</van-divider>
        <div class="container">
          <el-autocomplete
              class="inline-input"
              v-model="selCusName"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              @select="handleSelect"
          ></el-autocomplete>

        </div>

        <div class="submit">
          <van-button type="default" @click="showModal=false, activeType = '', key=false">取消</van-button>
          <van-button type="info" @click="onConfirm">确定</van-button>
        </div>
      </Modal>
    </div>

    <!--填写复盘-->
    <div>
      <Modal1 :visible="showModal1" @close="showModal1 = false, activeType = '', key=false" ref="taskModal1"
              id="taskModal1">
        <van-divider class="label" contentPosition="center">临时拜访</van-divider>
        <van-form @submit="saveReplay()">
          <van-field required label="走访名称：" label-width="6.5em"
                     :rules="customerForm.title" placeholder="（例：20230321华东走访）"
                     v-model="info.title"></van-field>
          <van-field required label="实际联系人" :rules="customerForm.realContact" placeholder="请填写实际联系人" label-width="6.5em"
                     v-model="info.realContact"></van-field>
          <van-field :required="true" label="业务推进成果" :rules="customerForm.pushResult" placeholder="请填写业务推进成果"
                     @click-input="clickItem(info.pushResult, '业务推进成果', false)"
                     v-model="info.pushResult" label-width="6.5em"></van-field>
          <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
          <van-field required label="业务量" :rules="customerForm.businessVolume" placeholder="业务量"
                     @click-input="clickItem(info.businessVolume, '业务量', false)"
                     v-model="info.businessVolume" label-width="6.5em" class="lable"></van-field>
          <van-field required label="盈利情况" :rules="customerForm.profitSituation" placeholder="应收情况"
                     @click-input="clickItem(info.profitSituation, '盈利情况', false)"
                     v-model="info.profitSituation" label-width="6.5em" class="lable"></van-field>
          <van-field label="其他" placeholder="人员等方面"
                     @click-input="clickItem(info.others, '其他', false)"
                     v-model="info.others" label-width="6.5em" class="lable"></van-field>
          <van-row>
            <van-col span="20">
              <van-field required autosize type="textarea" label="遗留问题" :rules="customerForm.problerms" placeholder="遗留问题"
                         label-width="6.5em"
                         v-model="info.problerms">
              </van-field>
            </van-col>
            <van-col span="4">
              <van-col offset="1" span="23" class="text info" style="font-size: 12px">
                填/不填
              </van-col>
              <van-switch :value="checked1" @input="onInput" size="20px" style="text-align:center;margin: 0px auto;"/>
            </van-col>
          </van-row>

          <div class="submit">
            <van-button type="default" native-type="button"
                        @click="showModal1=false, activeType = '', info ={}, key=false">取消
            </van-button>
            <van-button type="info" native-type="submit">推送</van-button>
          </div>
        </van-form>
      </Modal1>


      <dialog-card :data="DialogData" @close="show=false" :fieldValue="fieldValue" :show="show" @success="success"
                   @editVal="editVal"></dialog-card>
    </div>

    <!--放弃客户-->
    <van-dialog
        v-model="giveup"
        :title=title
        show-cancel-button
        :beforeClose="giveupBtn"
        confirm-button-text='是'
        cancel-button-text='否'
    >

      <van-row>
        <van-col>
          <van-field v-model="reason"
                     label="放弃原因"
                     type="textarea"
                     rows="3"
                     autosize
                     input-align="left"
                     left-icon="smile-o"
                     :placeholder=placeholder1 />

        </van-col>
      </van-row>
    </van-dialog>

    <!--转移-->
    <van-popup
        v-model="showChosePerson"
        closeable
        close-icon="close"
        position="bottom"
        :style="{ height: '100%', width: '100%' }"
    >
      <ChoseDept
          v-model="personList"
          @onCancel="showChosePerson = false"
          @onConfirm="onConfirmChosePerson"
      ></ChoseDept>
    </van-popup>

    <van-dialog
        v-model="translate"
        :title=title
        show-cancel-button
        :beforeClose="translateBtn"
        confirm-button-text='是'
        cancel-button-text='否'
    >
       <span v-if="selQts[0]" style="text-align: center; font-weight: bold">您是否要转移给<span
           style="color: red">{{ seltranslates.text }}</span></span>
      <van-row>
        <van-col>
          <van-field v-model="reason"
                     label="转移原因"
                     type="textarea"
                     rows="3"
                     autosize
                     input-align="left"
                     left-icon="smile-o"
                     :placeholder=placeholder1 />

        </van-col>
      </van-row>

    </van-dialog>

    <!--新增计划-->
    <van-dialog
        v-model="addPlan"
        title="新增计划"
        show-cancel-button
        :beforeClose="addBtn"
        confirm-button-text='确认生成'
        cancel-button-text='返回'
    >
           <span v-if="selQts.length>0" style="text-align: center; font-weight: bold">
             本次共选择{{ selQts.length }}家客户<br/>将合并生成一条出差计划<br/>请注意修改标题及往返时间</span>
    </van-dialog>

  </div>
</template>

<script>
import Modal from './../../components/Modal/MyCustomerModal';
import Modal1 from './../../components/Modal/MyCustomerModal';
import ChoseZfjh from './../../components/Modal/ChoseZfjh';
import ChoseDept from './../../components/Modal/ChoseDept';
import {
  getMyCustomer,
  savePlanvisitByMyCustomer,
  saveReplayByMyCustomer,
  customerManage,
  saveBulkCrmPlanvisit
} from '../../api/myCustomer.js'
import DialogCard from "../../components/DigitalCell/DialogCard";
import {Toast} from 'vant';

export default {
  name: "MyCustomer",
  components: {Modal, ChoseZfjh, ChoseDept, Modal1, DialogCard, Toast},
  mounted() {
    this.init()
  },
  watch: {
    //监听客户名头
    'searchCusName': function () {
      this.init();
    },
    'selQts': function () {
      if (this.selQts.length > 0) {
        this.title = this.selQts[0].value
      } else {
        this.title = '请选择'
      }
    },
  },
  data() {
    return {
      checked1: false,
      title: '请选择',
      placeholder1: '1.我司业务/对接人调整\n2.客户业务调整\n3.其他',
      reason: '',
      giveup: false,
      translate: false,
      addPlan: false,
      seltranslates: {text: '', value: ''},
      DialogData: {},
      fieldValue: '',
      show: false,
      placeholder: '请输入客户名称',
      searchCusName: '',
      myCus: [],
      activeList: [],
      personList: [],
      cusList: [],
      active: 0,
      zfjhType: '',
      zfjhTypes: [{
        type: '选择计划',
        use: false,
      }, {
        type: '新增计划',
        use: false,
      }],
      data: [{
        type: '临时拜访',
      }, {
        type: '放弃',
      }, {
        type: '转移',
      }],
      key: false,
      activeType: '',
      showModal: false,
      showModal1: false,
      customerName: '',
      searchQuery: '',
      options: [], // 完整列表
      selCusNames: [],
      selCusName: '',
      selZfjhs: [],
      selZfjh: {
        text: '',
        id: ''
      },
      selQts: [],
      zflx: '',
      zfjh: '',
      showChose: false,
      showChosePerson: false,
      info: {
        title: '',
        realContact: '',
        pushResult: '',
        businessVolume: '',
        profitSituation: '',
        others: '',
        problerms: '',
      },
      customerForm: {
        realContact: [
          {required: true, message: '', trigger: 'blur'},
        ],
        pushResult: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        otherDemand: [
          {required: true, message: '', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '', trigger: 'blur'},
        ],
        result: [
          {required: true, message: '', trigger: 'blur'},
        ],
        businessVolume: [
          {required: true, message: '', trigger: 'blur'},
        ],
        profitSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        others: [
          {required: true, message: '', trigger: 'blur'},
        ],
        problerms: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    init() {
      this.clearSz()
      getMyCustomer({
        userNo: this.$store.getters.userId,
        customerName: this.searchCusName
      }).then(res => {
        if (res.length > 0) {
          for (var i = 0; i < res.length; i++) {
            var item = {}
            item.value = res[i].value
            item.id = res[i].id
            item.text = res[i].text
            item.lastDate = res[i].lastDate
            item.createdPeople = res[i].createdPeople
            item.busgrp = res[i].busgrp
            item.check = false
            item.remarks = res[i].remarks
            this.myCus.push(item)
            this.selCusNames.push(item)
          }
        }
        console.log(this.myCus)
      }).catch(err => {
        console.log(err)
      });
    },
    clearSz() {
      this.selCusNames = []
      this.myCus = []
    },

    onInput(checked1) {
      console.log(checked1)
      this.checked1 = checked1;
      if (this.checked1) {
        this.info.problerms = '无'
      } else {
        this.info.problerms = ''
      }
    },


    close(e) {
      let index = this.cusList.findIndex((ele) => {
        return ele.customerName === e.customerName
      })
      this.cusList.splice(index, 1)
    },

    zfjhClick(item) {
      this.key = true;
      if (item.type == '新增计划') {
        if (this.selQts.length > 0) {
          this.addPlan = true
        } else {
          this.$router.push({path: '/BulkCrmsTravelPlanAdd/' + Math.random().toString(36).slice(2)})
        }
      } else {
        this.showModal = true
        if (this.selQts.length > 0) {
          console.log(this.cusList)
          for (let i = 0; i < this.selQts.length; i++) {
            var item = {}
            item.value = this.selQts[i].value
            item.id = this.selQts[i].id
            item.busgrp = this.selQts[i].busgrp
            item.text = this.selQts[i].text
            item.lastDate = this.selQts[i].lastDate
            item.createdPeople = this.selQts[i].createdPeople
            item.check = this.selQts[i].check
            let index = this.cusList.findIndex((ele) => {
              return ele.value === item.value
            })
            if (index == -1) {
              this.cusList.push(item)
            }
          }
        }
      }
    },

    oncheck(item) {
      if (item.type == '添加计划') {
        this.activeType = item.type
        item.use = true
        this.showModal = true
      } else if (item.type == '临时拜访') {

        //未到期客户
        var checked = []
        checked = this.myCus.filter((item) => {
          return item.check;
        });
        if (checked.length == 1) {
          this.activeType = item.type
          item.use = true
          this.showModal1 = true
        } else {
          this.$toast({
            message: '请选择一家客户进行复盘！',
            position: 'center'
          });
        }

      } else if (item.type == '放弃') {

        //未到期客户
        var checked = []
        checked = this.myCus.filter((item) => {
          return item.check;
        });
        if (checked.length == 1) {
          this.giveup = true
        } else {
          this.$toast({
            message: '请选择一家客户操作！',
            position: 'center'
          });
        }
      } else if (item.type == '转移') {

        //未到期客户
        var checked = []
        checked = this.myCus.filter((item) => {
          return item.check;
        });
        if (checked.length == 1) {
          this.showChosePerson = true
        } else {
          this.$toast({
            message: '请选择一家客户操作！',
            position: 'center'
          });
        }
      }
    },

    giveupBtn(action, done) {
      if (action === 'confirm') {
        if (this.reason == '') {
          Toast('请输入放弃原因')
          done(false);
        } else {
          const now = new Date();
          let currentTime = now.getFullYear() + '年' + (now.getMonth() + 1) + '月' + now.getDate() + '日' + now.toLocaleTimeString();
          let description = '于' + currentTime + '放弃客户：' + this.selQts[0].value
          customerManage({
            fzr: this.$store.getters.userId,
            customerName: this.selQts[0].value,
            crmCustomerid: this.selQts[0].id,
            type: '00',
            tranFzr: '',
            reason: this.reason,
            description: description,
          }).then(res => {
            done()
            this.reason = ''
            Toast.success('已推送至运管中心，请等待推送通知！');
          }).catch(err => {
            console.log(err)
          });
        }

      } else if (action === 'cancel') {
        done()
        this.reason = ''
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },

    addBtn(action, done) {
      if (action === 'confirm') {
        console.log(this.selQts)
        const now = new Date();
        let titleName = now.getFullYear() + '-' + (now.getMonth() + 1) + '-'  + now.getDate() + '走访';
        let titleId = Math.random().toString(36).slice(2)
        Toast.loading({
          message: '生成中...',
          forbidClick: true,
          loadingType: 'spinner'
        });
        for(var i=0; i<this.selQts.length; i++){
          saveBulkCrmPlanvisit({
            customerName: this.selQts[i].value,
            crmCustomerid: this.selQts[i].id,
            titleName: titleName,
            titleId: titleId,
            plandateStart: this.formatDate(now),
            plandateEnd: this.formatDate(now),
            travelType: '001',
            interviewType: '',
            goalPlan: '',
            createdPeople: this.$store.getters.userId,
            vessel: []
          }).then(res => {
            done()
            this.reason = ''
          }).catch(err => {
            console.log(err)
          });
        }

        Toast.success('添加成功！2秒后进入走访计划编辑页面');
        setTimeout(() => {
          this.$router.push({path: "/BulkCrmsTravelPlanDetail/" + titleId});
        }, 2000)



      } else if (action === 'cancel') {
        this.activeType = ''
        this.key = false
        done()
      }
    },


    translateBtn(action, done) {
      if (action === 'confirm') {
        if (this.reason == '') {
          Toast('请输入转移原因')
          done(false);
        } else {
          const now = new Date();
          let currentTime = now.getFullYear() + '年' + (now.getMonth() + 1) + '月' + now.getDate() + '日' + now.toLocaleTimeString();
          let description = '于' + currentTime + '转移客户：' + this.selQts[0].value + '给' + this.seltranslates.text
          customerManage({
            fzr: this.$store.getters.userId,
            customerName: this.selQts[0].value,
            crmCustomerid: this.selQts[0].id,
            type: '01',
            tranFzr: this.seltranslates.value,
            reason: this.reason,
            description: description,
          }).then(res => {
            done()
            this.showChosePerson = false
            this.reason = ''
            Toast.success('已推送至运管中心，请等待推送通知！');
          }).catch(err => {
            console.log(err)
          });
        }
      } else if (action === 'cancel') {
        done()
      }
    },

    onSearch() {
      // 可以添加延时处理，防止频繁查询
      // 例如: setTimeout(() => { /* 查询逻辑 */ }, 300);
      // 当前示例中直接进行查询
    },
    handleSearch(search) {
      this.searchQuery = search
      // 模糊查询逻辑，例如使用正则或其他方法
      this.options = this.options.filter(option =>
          option.label.toLowerCase().includes(search.toLowerCase())
      )
    },

    querySearch(queryString, cb) {
      var selCusNames = this.selCusNames;
      var results = queryString ? selCusNames.filter(this.createFilter(queryString)) : selCusNames;
      console.log(queryString)
      console.log(selCusNames)
      console.log(results)
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    formatter(val) {
      return val.trim()
    },

    createFilter(queryString) {
      return (selCusName) => {
        return (selCusName.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
      };
    },

    handleSelect(item) {
      let index = this.cusList.findIndex((ele) => {
        return ele.value === item.value
      })
      if (index == -1) {
        this.cusList.push(item)
      } else {
        this.$toast({
          message: '不能重复选择客户！',
          position: 'top'
        });
      }
      this.selCusName = ''
      console.log(this.cusList)
    },

    onConfirmChose(e) {
      console.log(e)
      this.showChose = false
      this.selZfjh = e
    },

    removeTrailingComma(str) {
      if (str.endsWith(',')) {
        return str.substring(0, str.length - 1);
      }
      return str;
    },
    onConfirmChosePerson(e) {
      if (e.length > 0) {
        var text = ''
        var value = ''
        for (let i = 0; i < e.length; i++) {
          text += e[i].text + ','
          value += e[i].val + ','
        }
        this.seltranslates.text = this.removeTrailingComma(text)
        this.seltranslates.value = this.removeTrailingComma(value)
        this.translate = true

        // this.showChosePerson = false
      } else {
        this.$toast({
          message: '请选择转移人员！',
          position: 'top'
        });
      }

    },

    onConfirm() {
      if (this.selZfjh.text == '') {
        this.$toast({
          message: '请完善走访计划！',
          position: 'top'
        });
      } else if (this.cusList.length == 0) {
        this.$toast({
          message: '请补充客户！',
          position: 'top'
        });
      } else {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner'
        });
        for (let i = 0; i < this.cusList.length; i++) {
          savePlanvisitByMyCustomer({
            createdPeople: this.$store.getters.userId,
            customerName: this.cusList[i].value,
            crmCustomerid: this.cusList[i].id,
            titleId: this.selZfjh.val
          }).then(res => {
            Toast.success('添加成功！2秒后进入走访计划页面');
            setTimeout(() => {
              this.$router.push({path: '/BulkCrmsTravelPlanDetail/' + this.selZfjh.val})
            }, 2000)
          }).catch(err => {
            console.log(err)
          });
        }
      }
    },


    boolRotate(index) {
      this.myCus[index].check = !this.myCus[index].check
      if (this.myCus[index].check) {
        this.selQts.push(this.myCus[index])
      } else {
        this.selQts.forEach((item, index1) => {
          if (item.value == this.myCus[index].value) { //存在就进来
            this.selQts.splice(index1, 1)
          }
        })
        let index1 = this.cusList.findIndex((ele) => {
          return ele.value === this.myCus[index].value
        })
        if (index1 != -1) {
          this.cusList.splice(index1, 1)
        }
      }
      console.log(this.selQts)
    },

    clickItem(val, text, bool) {
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },

    success(res) {
      this.show = res
    },

    editVal(val) {
      if (val.text == '业务推进成果') {
        this.info.pushResult = val.value
      } else if (val.text == '业务量') {
        this.info.businessVolume = val.value
      } else if (val.text == '盈利情况') {
        this.info.profitSituation = val.value
      } else if (val.text == '其他') {
        this.info.others = val.value
      }
    },

    saveReplay(formName) {
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      saveReplayByMyCustomer({
        titleName: this.info.title,
        customerName: this.selQts[0].value,
        crmCustomerid: this.selQts[0].id,
        realContact: this.info.realContact,
        pushResult: this.info.pushResult,
        businessVolume: this.info.businessVolume,
        profitSituation: this.info.profitSituation,
        problerms: this.info.problerms,
        others: this.info.others,
        createdPeople: this.$store.getters.userId
      }).then(res => {
        Toast.success('推送成功');
        this.showModal1 = false
        this.key = false
        this.activeType = ''
        this.info = {
          title: '',
          realContact: '',
          pushResult: '',
          businessVolume: '',
          profitSituation: '',
          others: '',
          problerms: '',
        }
      }).catch(err => {
        console.log(err)
      });
    },

    toDetail(e){
      if(e.remarks=='船方'){
        this.$router.push({path: '/CustomerListEditCF/' + e.id})
      }else{
        this.$router.push({path: '/CustomerListEdit/' + e.id})
      }
      console.log("跳转页面",e)
    }
  }
}
</script>

<style scoped>

.my-customer {
  background-color: white;
  padding-bottom: 120px;
}

.my-customer .container-button {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: auto;
  scrollbar-width: none;
  padding-right: 11px;
}

.my-customer .slide {
  flex: none;
  width: auto;
  text-align: left;
}

.my-customer .menu-tabs .van-tab--active {
  color: #FFFFFF; /* 字体颜色 */
  background-color: #FF8917; /* 标签背景颜色 */
  border-radius: 40px; /* 圆角标签背景 */
}

.my-customer .active {
  float: left;
  margin-left: 10px;
  padding: 10px;
  background: #efc531;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.my-customer .lable {
  margin-left: 15px;
  font-size: 12px;
}

.my-customer .classification {
  float: left;
  margin-left: 10px;
  padding: 10px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.my-customer .contain-cusName {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.my-customer .contain-time {
  text-align: left;
  font-size: 14px;
  font-family: 微软雅黑;
  margin-top: 5px;
}

.my-customer .contain-busgrp {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  font-family: 微软雅黑;
  margin-top: 5px;
}

.my-customer .contain-detail {
  text-align: left;
  font-size: 14px;
  font-family: 微软雅黑;
  margin-top: 10px;
  color: #5CA4F4;
}

.my-customer .container {
  position: relative; /* 确保父容器是相对定位 */
}

.my-customer .container .floating-list {
  position: absolute; /* 绝对定位 */
  left: 15%; /* 距离左侧10px */
  z-index: 100; /* 设置较高的z-index值确保浮在上面 */
  width: 70%;
  margin-top: 1px;
  background-color: #F5F5F6;
}

.my-customer .cusList {
  font-size: 15px;
}

.my-customer .submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.my-customer .submit .van-button {
  width: 150px;
}


.my-customer .label {
  font-size: .9rem;
  font-weight: bold;
  color: #05060f99;
}

.my-customer .customerList {
  margin: 17px 10px;
  padding: 5px;
  border-radius: 5px;
  background: white;
  /*box-shadow: 1px 1px 5px #E1C191;*/
  position: relative;
}

.my-customer .customerListCheck {
  margin: 17px 10px;
  padding: 5px;
  border-radius: 5px;
  background: #E1C191;
  /*box-shadow: 1px 1px 5px #E1C191;*/
  position: relative;
}

.my-customer .select-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.my-customer .driver {
  background-color: #473333;
  margin: 10px 11px 6px 4%;
}

.my-customer .fixed-button {
  position: fixed; /* 使用fixed定位 */
  z-index: 100; /* 确保标签在最上层 */
  width: 100%;
  background-color: white;
}

.my-customer .fixed-button .search {
  background-color: white;
}

.my-customer .fixed-tab {
  position: relative; /* 使用fixed定位 */
  z-index: 80; /* 确保标签在最上层 */
  top: 108px;
  bottom: 20px;
  width: 100%;
}

</style>

<style>
.my-customer .menu-tabs .van-tab--active {
  color: #FF8917; /* 字体颜色 */
}

.my-customer .menu-tabs .van-tabs__line {
  width: 40px; /*宽度 */
  height: 0px; /* 高度 */
}

.my-customer .cusList .van-icon {
  color: red;
}

.my-customer .container .el-input__inner {
  max-width: 100%;
  /*height: 44px;*/
  background-color: #05060f0a;
  border-radius: .5rem;
  padding: 0 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  transition: border-color .3s cubic-bezier(.25, .01, .25, 1) 0s, color .3s cubic-bezier(.25, .01, .25, 1) 0s, background .2s cubic-bezier(.25, .01, .25, 1) 0s;
}

.my-customer .zfjh .el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.my-customer .zfjh .el-icon-arrow-down {
  font-size: 12px;
}

.my-customer .zfjh .el-input__inner {
  border: none;
}

.my-customer .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}

.my-customer .container-button .container-button-select .el-input__inner {
  border: none;
  background-color: #f7f7f7;
  font-size: 0.8rem;
  height: 38px;
  width: 100%;
}

.my-customer .container-button .container-button-select1 .el-input__inner {
  border: none;
  background-color: #efc531;
  font-size: 0.8rem;
  height: 38px;
  width: 100%;
}


.my-customer .container-button .el-input__inner::placeholder {
  color: black;
}

.element::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}

</style>